<template>
  <span v-if="dayTimes" :class="{ locked: isLocked}">
    <span class="status-icon no-print">
      <i class="fa" :class="getStatusIcon(individual)" :title="getStatusTooltip(individual)"></i>
    </span>
    <span title="Matin" v-b-tooltip.hover>
      <i class="far presence-moment" :class="[isPresentMorning ? 'fa-check-square' : 'fa-square']" @click="clickMorning" v-if="dayTimes.morning"></i>
      <i class="far fa-square not-enrolled" v-else></i>
    </span>
    <span title="Repas" v-b-tooltip.hover>
      <i class="far presence-moment" :class="[isPresentLunch ? 'fa-check-square' : 'fa-square']" @click="clickLunch" v-if="dayTimes.lunch"></i>
      <i class="far fa-square not-enrolled" v-else></i>
    </span>
    <span title="Après-midi" v-b-tooltip.hover>
      <i class="far presence-moment" :class="[isPresentAfternoon ? 'fa-check-square' : 'fa-square']" @click="clickAfternoon" v-if="dayTimes.afternoon"></i>
      <i class="far fa-square not-enrolled" v-else></i>
    </span>
    <span title="Soirée" v-b-tooltip.hover v-if="showEvening">
      <i class="far presence-moment" :class="[isPresentEvening ? 'fa-check-square' : 'fa-square']" @click="clickEvening" v-if="dayTimes.evening"></i>
      <i class="far fa-square not-enrolled" v-else></i>
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import moment from 'moment'
import { mapActions } from 'vuex'
import { getInscriptionsDayTimes, SeanceLock } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'

export default {
  name: 'PresenceMoment',
  mixins: [BackendMixin],
  props: {
    inscriptions: Array,
    day: String,
    youthHome: [String, Number],
    seanceType: [String, Number],
    seancePeriod: [String, Number],
    individual: Object,
    presents: Array,
    showEvening: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      seanceDateAsString: '',
      loadingName: 'daily-presence',
      dayTimes: null,
      isPresentMorning: false,
      isPresentLunch: false,
      isPresentAfternoon: false,
      isPresentEvening: false,
      individualsMap: {},
    }
  },
  watch: {
    inscriptions: function() {
      this.dayTimes = getInscriptionsDayTimes(this.inscriptions)
    },
  },
  computed: {
    isLocked() {
      if (this.inscriptions) {
        return this.inscriptions.filter(ins => ins.seance.lock === SeanceLock.Total).length > 0
      }
      return false
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    clickMorning: function() {
      if (this.isLocked) {
        return
      }
      this.isPresentMorning = !this.isPresentMorning
      this.onSave()
    },
    clickLunch: function() {
      if (this.isLocked) {
        return
      }
      this.isPresentLunch = !this.isPresentLunch
      this.onSave()
    },
    clickAfternoon: function() {
      if (this.isLocked) {
        return
      }
      this.isPresentAfternoon = !this.isPresentAfternoon
      this.onSave()
    },
    clickEvening: function() {
      if (this.isLocked) {
        return
      }
      this.isPresentEvening = !this.isPresentEvening
      this.onSave()
    },
    checkPresents() {
      if (this.presents) {
        if (this.presents[0]) {
          this.isPresentMorning = true
        }
        if (this.presents[1]) {
          this.isPresentLunch = true
        }
        if (this.presents[2]) {
          this.isPresentAfternoon = true
        }
        if (this.presents[3]) {
          this.isPresentEvening = true
        }
      }
    },
    getStatusIcon(individual) {
      if (this.isLocked) {
        return 'fa-lock'
      }
      const status = this.individualsMap[individual.id] || 0
      switch (status) {
        case 1:
          return 'fa-ellipsis-h'
        case 2:
          return 'fa-check-circle success'
        case 3:
          return 'fa-warning error'
        default:
          return 'fa-ellipsis-h fa-transparent'
      }
    },
    getStatusTooltip(individual) {
      const status = this.individualsMap[individual.id] || 0
      switch (status) {
        case 3:
          return 'Une erreur s\'est produite. Vos données n\'ont pas été enregistrées'
        default:
          return ''
      }
    },
    async onSave() {
      if (this.isLocked) {
        return
      }
      const data = {
        individual: this.individual.id,
        morning: this.isPresentMorning,
        lunch: this.isPresentLunch,
        afternoon: this.isPresentAfternoon,
        evening: this.isPresentEvening,
      }
      this.individualsMap[this.individual.id] = 1 // en cours
      this.individualsMap = { ...this.individualsMap, }
      let url = '/api/youth/daily-presence/' + this.day + '/' + this.youthHome + '/' +
        this.seanceType + '/' + this.seancePeriod + '/'
      let backendApi = new BackendApi('post', url)
      const individualId = this.individual.id
      try {
        await backendApi.callApi(data)
        this.individualsMap[individualId] = 2 // OK
        this.individualsMap = { ...this.individualsMap, }
        const that = this
        setTimeout(
          function() {
            that.individualsMap[individualId] = 0 // reset
            that.individualsMap = { ...that.individualsMap, }
          },
          200
        )
      } catch (err) {
        this.individualsMap[this.individual.id] = 3 // error
        this.individualsMap = { ...this.individualsMap, }
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
    this.seanceDateAsString = this.day || dateToString(moment().toDate(), 'YYYY-MM-DD')
    this.dayTimes = getInscriptionsDayTimes(this.inscriptions)
    this.checkPresents()
  },
}
</script>
<style lang="less">
.presence-moment{
  font-size: 28px;
  margin: 2px 1px;
  vertical-align: top;
  color: #222;
}
.locked .presence-moment {
  color: #888;
}
.not-enrolled{
  font-size: 34px;
  margin: 2px 1px;
  vertical-align: top;
  color: #ccc;
  background-color: #e0e0e0;
}
.success {
  color: #73956f;
}
.error {
  color: #c84444;
}
</style>
