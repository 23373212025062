<template>
  <span class="seance-clocking" v-if="hasPerm('youth.view_seanceinscription')">
    <div v-if="individual && manager">
      <span v-if="hasChanged && nameBefore && !noMark" class="changed-icon" title="à confirmer" v-b-tooltip>
        <i class="fa fa-asterisk"></i>
      </span>
      <span
        v-for="seance in manager.getSeances()"
        :key="seance.id"
        :style="'background: ' + manager.getSeanceColor(seance)"
        :title="seance.name"
        class="grid-col"
        :class="{ 'label-col': label, }"
      >
        <div v-if="label">
          {{ seance.getCodeOrAlias() }}
        </div>
        <div class="checkbox-holder">
          <input
            type="checkbox"
            :checked="seance.doesIndividualHaveInscription(individual.id)"
            @click="onSeanceClicked(seance, individual)"
            :disabled="!manager.isInscriptionPossible(seance, individual)"
            class="inline"
            :class="{ checked : seance.doesIndividualHaveInscription(individual.id) }"
          /><span></span>
        </div>
      </span>
      <span v-if="hasChanged && !nameBefore && !noMark" class="changed-icon" title="à confirmer" v-b-tooltip>
        <i class="fa fa-asterisk"></i>
      </span>
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { SeanceInscriptionManager } from '@/utils/youth'

export default {
  name: 'SeanceClocking',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    day: String,
    element: Object,
    seanceType: Object,
    youthHome: Object,
    period: Object,
    seances: Array,
    rules: Array,
    checkSeance: {
      type: Object,
      default: null,
    },
    nameBefore: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: false,
    },
    noMark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorText: '',
      manager: null,
      reloadInvoicing: false,
    }
  },
  computed: {
    individual() {
      if (this.element) {
        return this.element.individual
      }
      return null
    },
    entity() {
      if (this.element) {
        return this.element.entity
      }
      return null
    },
    entitySeances() {
      if (this.element) {
        return this.element.entitySeances
      }
      return []
    },
    hasChanged() {
      if (this.element) {
        return this.element.entitySeances.filter(
          seance => seance.hasCancellations() || seance.hasNewInscriptions()
        ).length > 0
      }
      return false
    },
  },
  watch: {
    element: function() {
      this.init()
    },
    youthHome: function() {},
    rules: function() {
      this.manager.setInscriptionRules(this.rules)
    },
    checkSeance: function() {
      if (this.checkSeance && this.checkSeance.duration) {
        const siblings = this.entitySeances.filter(elt => elt.isSameMoment(this.checkSeance))
        const hasChanged = siblings.filter(elt => elt.hasChanged()).length > 0
        const preInscriptions = siblings.filter(
          elt => (elt.duration === 0) && elt.doesIndividualHaveInscription(this.individual.id)
        )
        const existingInscriptions = siblings.filter(
          elt => (elt.duration > 0) && elt.doesIndividualHaveInscription(this.individual.id)
        )
        if ((preInscriptions.length > 0) && (existingInscriptions.length === 0)) {
          // on coche la case correspondante si aucun changement déjà pour ce moment de journée
          for (const seance of siblings) {
            if (seance.code === this.checkSeance.code) {
              seance.setIndividualInscription(this.individual.id)
            } else {
              seance.resetIndividualInscription(this.individual.id)
            }
          }
        }
        this.$emit('seance-checked', { individual: this.individual.id, })
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      this.manager = new SeanceInscriptionManager(this.youthHome, this.seanceType, this.period)
      this.manager.setIndividual(this.individual, this.entity)
      const entitySeances = []
      const seanceMap = new Map()
      for (const seance of this.entitySeances) {
        seanceMap.set(seance.id, seance)
      }
      for (const seance of this.seances) {
        if (seanceMap.has(seance.id)) {
          entitySeances.push(seanceMap.get(seance.id))
        } else {
          entitySeances.push(seance.toEntitySeance(this.individual))
        }
      }
      this.manager.setSeances(entitySeances)
      this.manager.setInscriptionRules(this.rules)
      this.$emit(
        'seances-updated',
        { seances: this.manager.individualSeances, manager: this.manager, }
      )
    },
    onSeanceClicked(seance, individual) {
      this.manager.setIndividualInscription(seance, individual)
      this.$emit('seance-clicked', { seance, individual, manager: this.manager, })
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less" scoped>
  .inscription-line, .individual-line {
    padding: 4px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 2px;
  }
  .individual-line a{
    color: #000 !important;
  }
  .individual-line.selected {
    background: #222;
    color: #fff !important;
  }
  .individual-line.selected a{
    color: #fff !important;
  }
  .individual-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  .error-text {
    padding: 5px;
    color: #000;
    background: #f78888;
  }
  .col-param .section {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px #e0e0e0;
  }
  .grid-col {
    display: inline-block;
    height: 24px;
    width: 36px;
    text-align: center;
  }
  .label-col {
    border: solid 1px #222;
    font-size: 10px;
    height: unset;
  }
  .grid-col .checkbox-holder {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .grid-col .checkbox-holder input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
    cursor: pointer;
  }
  .grid-col .checkbox-holder input[type="checkbox"] + span:before {
    font-family: 'Font Awesome 6 Free';
    font-size: 16pt;
    content: '\f0c8';
    display: inline-block;
    width: 16pt;
    margin-right: 2px;
    margin-top: -2px;
  }
  .grid-col .checkbox-holder input[type="checkbox"]:checked + span:before {
    content: '\f14a';
  }
  .grid-col .checkbox-holder input[type="checkbox"]:focus + span:before {
    outline: 1px dotted #aaa;
  }
 .grid-col .checkbox-holder input[type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: not-allowed;
  }
  .changed-icon {
    font-size: 10px;
    display: inline-block;
    margin-right: 2px;
    margin-left: 2px;
    vertical-align: middle;
    color: #444;
  }
</style>
